<template>
  <div id="components-layout-demo-basic">
    <a-layout>
        <IndexHeader 
        />
      <a-layout>
        <IndexContent />
        <IndexSider />
      </a-layout>
      <IndexFooter />
    </a-layout>
  </div>
</template>

<script>
import IndexHeader from "@/layouts/index/IndexHeader"
import IndexFooter from "@/layouts/index/IndexFooter"
import IndexContent from "@/layouts/index/IndexContent"
import IndexSider from "@/layouts/index/IndexSider"
export default {
  components: {
    IndexHeader,
    IndexFooter,
    IndexContent,
    IndexSider
  }
}
</script>

<style scoped>
#components-layout-demo-basic {
  text-align: center;
}
#components-layout-demo-basic .ant-layout-header,
#components-layout-demo-basic .ant-layout-footer {
  background: whitesmoke;
}
#components-layout-demo-basic .ant-layout-footer {
  line-height: 1.5;
}
#components-layout-demo-basic .ant-layout-sider {
  background: whitesmoke;
  line-height: 120px;
}
#components-layout-demo-basic .ant-layout-content {
  background: whitesmoke;
  min-height: 120px;
  line-height: 120px;
}
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
</style>