<template>
  <a-layout-content><h1><a-icon type="tool" />本页面正在建设中，请通过上面的导航栏访问相应功能。</h1></a-layout-content>
</template>

<script>
export default {

}
</script>

<style>

</style>