<template>
  <a-layout-footer style="text-align: center">
      Global Content Manager | 综合内容管理系统 © 2020-{{ this_year }} Created by <a target="_blank" href="https://github.com/lpchg1992">LPC</a>
    </a-layout-footer>
</template>

<script>
let moment = require("moment")
export default {
    data() {
        return {
            
        }
    },
    computed: {
        this_year() {
            return moment().year()
        }
    }
}
</script>

<style>

</style>