<template>
<a-row justify="space-around" align="middle" :style="{ background: '#fff'}">
  <a-col :span="20">
  <a-menu v-model:selectedKeys="current" mode="horizontal">
    <a-sub-menu v-if="$store.state.user_login_auth.user['authenticated'] === true">
      <template #title>
        <span class="submenu-title-wrapper">
          <setting-outlined />
          博客
        </span>
      </template>
      <a-menu-item-group title="博客">
        <a-menu-item key="setting:1"><router-link to="/">测试中</router-link></a-menu-item>
        <a-menu-item key="setting:2">Option 2</a-menu-item>
      </a-menu-item-group>
    </a-sub-menu>
    <a-sub-menu v-if="$store.state.user_login_auth.user['authenticated'] === true">
      <template #title>
        <span class="submenu-title-wrapper">
          <setting-outlined />
          配方综合管理系统
        </span>
      </template>
      <a-menu-item-group title="数据管理">
        <a-menu-item key="setting:3"><router-link to="/basic_data">元数据管理</router-link></a-menu-item>
        <a-menu-item key="setting:1"><router-link to="/import_excel">从Excel导入数据</router-link></a-menu-item>
        <a-menu-item key="setting:2"><router-link to="/mainapp">数据综合统计管理</router-link></a-menu-item>
        <a-menu-item key="setting:4"><router-link to="/nutrition_indicators">营养指标数据管理</router-link></a-menu-item>
      </a-menu-item-group>
    </a-sub-menu>
  </a-menu>
  </a-col>
<a-col :style="{ background: '#fff'}" :span="4" id="auth-popover" >
  <AuthAndLogout />
</a-col>
  </a-row>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { SettingOutlined } from '@ant-design/icons-vue';
import AuthAndLogout from '@/components/auth/AuthAndLogout.vue'
export default defineComponent({
  setup() {
    const current = ref(['mail']);
    return {
      current,
    };
  },
  components: {
    SettingOutlined,
    AuthAndLogout
  },
});
</script>
<style scoped>
</style>